import _classCallCheck from "C:/projects/michelin-hp/node_modules/@babel/runtime/helpers/classCallCheck";
import _defineProperty from "C:/projects/michelin-hp/node_modules/@babel/runtime/helpers/defineProperty";

var State = function State(model) {
  _classCallCheck(this, State);

  _defineProperty(this, "items", []);

  _defineProperty(this, "item", null);

  _defineProperty(this, "pagination", {});

  _defineProperty(this, "model", '');

  this.model = model;
};

export { State as default };