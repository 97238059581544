export function width(state) {
  return state.width;
}
export function height(state) {
  return state.height;
}
export function rim(state) {
  return state.rims;
}
export function getWidth(state) {
  return state.valueWidth;
}
export function getHeight(state) {
  return state.valueHeight;
}
export function getRim(state) {
  return state.valueRim;
}