import Notify from 'quasar/src/plugins/Notify.js';;
Notify.setDefaults({
  position: 'top',
  textColor: 'white'
});
export default (function (_ref) {
  var Vue = _ref.Vue;

  Notify.error = function (message) {
    var position = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'top';
    Notify.create({
      color: 'red-5',
      message: message,
      position: position,
      icon: 'error_outline',
      actions: [{
        icon: 'eva-close',
        handler: Function,
        color: 'red-3'
      }]
    });
  };

  Notify.success = function (message) {
    var position = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'top';
    Notify.create({
      color: 'green-6',
      message: message,
      position: position,
      icon: 'check_circle_outline',
      actions: [{
        label: 'Cerrar',
        handler: Function,
        color: 'green-3'
      }]
    });
  };

  Notify.warning = function (message) {
    var position = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'top';
    Notify.create({
      color: 'orange-5',
      message: message,
      position: position,
      icon: 'warning',
      actions: [{
        label: 'Cerrar',
        handler: Function,
        color: 'white'
      }]
    });
  };

  Notify.default = function (message) {
    var position = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'top';
    Notify.create({
      color: 'grey-8',
      message: message,
      position: position,
      icon: 'check_circle_outline',
      actions: [{
        label: 'Cerrar',
        handler: Function,
        color: 'grey-2'
      }]
    });
  };

  Vue.prototype.$notify = Notify;
});