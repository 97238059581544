var apiErrors = {
  500: {
    DEFAULT: 'Sucedió un error desconocido al realizar la petición al servidor. Por favor contacte a soporte.'
  },
  401: {
    DEFAULT: 'Debe estar autenticado para poder acceder a este recurso',
    AUTH_TOKEN_COMPROMISED: 'Su sesión ha sido usada en otro dispositivo y ha sido desactivada. Vuelva a iniciar sesión para ingresar nuevamente a la aplicación.',
    AUTH_INVALID_CREDENTIALS: 'Su usuario o contraseña son incorrectos',
    AUTH_TOKEN_EXPIRED: 'Su sesión a expirado o contraseña son incorrectos'
  },
  404: {
    DEFAULT: 'El MSPN no fue encontrado en el servidor'
  },
  403: {
    DEFAULT: 'No tienes permisos para realizar esta acción'
  },
  422: {
    DEFAULT: 'Existen errores en la forma',
    INVALID_PARAMETERS: 'Existen errores en la forma',
    ALREADY_EXISTS: 'Ya existe un recurso con el mismo nombre'
  }
};

var getError = function getError(status) {
  var code = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'DEFAULT';

  var _status = apiErrors[status] ? status : 500;

  var _code = apiErrors[_status][code] ? code : 'DEFAULT';

  return apiErrors[_status][_code];
};

export default getError;