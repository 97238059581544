export function isAuthenticated(state, authenticated) {
  state.isAuthenticated = authenticated;
}
export function email(state, payload) {
  state.email = payload;
}
export function code(state, payload) {
  state.code = payload;
}
export function me(state, data) {
  state.me = data;
}