export function brands(state) {
  return state.brands;
}
export function models(state) {
  return state.models;
}
export function years(state) {
  return state.years;
}
export function version(state) {
  return state.version;
}
export function item(state) {
  return state.item;
}