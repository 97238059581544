export function title(state, title) {
  state.title = title;
}
export function navColor(state, navColor) {
  state.navColor = navColor;
}
export function navTextColor(state, navTextColor) {
  state.navTextColor = navTextColor;
}
export function showNav(state, showNav) {
  state.showNav = showNav;
}