import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.map";
import "core-js/modules/es.array.reduce";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.promise";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.replace";

/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.conf.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/
import App from 'app/src/App.vue';
var appOptions = App.options
/* Vue.extend() */
|| App;
var appPrefetch = typeof appOptions.preFetch === 'function';

function getMatchedComponents(to, router) {
  var route = to ? to.matched ? to : router.resolve(to).route : router.currentRoute;

  if (!route) {
    return [];
  }

  return Array.prototype.concat.apply([], route.matched.map(function (m) {
    return Object.keys(m.components).map(function (key) {
      var comp = m.components[key];
      return {
        path: m.path,
        c: comp.options
        /* Vue.extend() */
        || comp
      };
    });
  }));
}

export function addPreFetchHooks(router, store, publicPath) {
  // Add router hook for handling preFetch.
  // Doing it after initial route is resolved so that we don't double-fetch
  // the data that we already have. Using router.beforeResolve() so that all
  // async components are resolved.
  router.beforeResolve(function (to, from, next) {
    var urlPath = window.location.href.replace(window.location.origin, ''),
        matched = getMatchedComponents(to, router),
        prevMatched = getMatchedComponents(from, router);
    var diffed = false;
    var preFetchList = matched.filter(function (m, i) {
      return diffed || (diffed = !prevMatched[i] || prevMatched[i].c !== m.c || m.path.indexOf('/:') > -1 // does it has params?
      );
    }).filter(function (m) {
      return m.c && typeof m.c.preFetch === 'function';
    }).map(function (m) {
      return m.c.preFetch;
    });

    if (appPrefetch === true) {
      appPrefetch = false;
      preFetchList.unshift(appOptions.preFetch);
    }

    if (preFetchList.length === 0) {
      return next();
    }

    var hasRedirected = false;

    var redirect = function redirect(url) {
      hasRedirected = true;
      next(url);
    };

    var proceed = function proceed() {
      if (hasRedirected === false) {
        next();
      }
    };

    preFetchList.reduce(function (promise, preFetch) {
      return promise.then(function () {
        return hasRedirected === false && preFetch({
          store: store,
          currentRoute: to,
          previousRoute: from,
          redirect: redirect,
          urlPath: urlPath,
          publicPath: publicPath
        });
      });
    }, Promise.resolve()).then(proceed).catch(function (e) {
      console.error(e);
      proceed();
    });
  });
}