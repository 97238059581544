import axios from 'axios';
import Notify from 'quasar/src/plugins/Notify.js';;
import getError from '../utils/api-errors';
var axiosInstance = axios.create({
  baseURL: process.env.API
});
export default (function (_ref) {
  var Vue = _ref.Vue,
      store = _ref.store,
      router = _ref.router;
  Vue.prototype.$axios = axiosInstance;
  axiosInstance.prototype.cancelError = false;
  axiosInstance.interceptors.response.use(function (config) {
    return config;
  }, function (error) {
    if (error.config.notify !== false) {
      Notify.error(getError(error.response.status, error.response.data.code));
    }

    if (error.response.status == 401) {
      store.dispatch('auth/clear');
      router.push('/login');
    }

    return error;
  });
});
export { axiosInstance };