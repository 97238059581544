export function width(state, data) {
  state.width = data;
}
export function height(state, data) {
  state.height = data;
}
export function rim(state, data) {
  state.rims = data;
}
export function setWidth(state, data) {
  state.valueWidth = data;
}
export function setHeight(state, data) {
  state.valueHeight = data;
}
export function setRim(state, data) {
  state.valueRim = data;
}