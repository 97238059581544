export function isAuthenticated(state) {
  return state.isAuthenticated;
}
export function email(state) {
  return state.email;
}
export function code(state) {
  return state.code;
}
export function me(state) {
  return state.me;
}