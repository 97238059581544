export function title(_ref, title) {
  var commit = _ref.commit;
  commit('title', title);
}
export function navColor(_ref2, navColor) {
  var commit = _ref2.commit;
  commit('navColor', navColor);
}
export function navTextColor(_ref3, navTextColor) {
  var commit = _ref3.commit;
  commit('navTextColor', navTextColor);
}
export function showNav(_ref4, showNav) {
  var commit = _ref4.commit;
  commit('showNav', showNav);
}