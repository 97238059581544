export function brands(state, response) {
  state.brands = response;
}
export function models(state, response) {
  state.models = response;
}
export function years(state, response) {
  state.years = response;
}
export function version(state, response) {
  state.version = response;
}
export function item(state, response) {
  state.item = response;
}