import "core-js/modules/es.object.to-string";
import "core-js/modules/es.promise";
var routes = [{
  path: '/',
  component: function component() {
    return import('layouts/main-layout.vue');
  },
  children: [{
    path: '',
    name: 'home',
    component: function component() {
      return import('pages/index.vue');
    },
    children: [{
      path: '',
      name: 'login-form',
      meta: {
        auth: false
      },
      component: function component() {
        return import('pages/login/login-form.vue');
      }
    }, {
      path: 'email-recover',
      name: 'email-recover',
      meta: {
        auth: false
      },
      component: function component() {
        return import('pages/login/recover-email.vue');
      }
    }, {
      path: 'code-verification',
      name: 'code-verfication',
      meta: {
        auth: false
      },
      component: function component() {
        return import('pages/login/verify-code.vue');
      }
    }, {
      path: 'change-password',
      name: 'change-password',
      meta: {
        auth: false
      },
      component: function component() {
        return import('pages/login/change-password.vue');
      }
    }, {
      path: 'register-form',
      name: 'register-form',
      meta: {
        auth: false
      },
      component: function component() {
        return import('pages/login/register-form.vue');
      }
    }]
  }, {
    name: 'search',
    path: 'search',
    meta: {
      auth: false
    },
    component: function component() {
      return import('layouts/search-layout.vue');
    },
    children: [{
      name: 'brand',
      path: 'brand',
      component: function component() {
        return import('pages/search/brands.vue');
      }
    }, {
      name: 'models',
      path: 'brand/models/:brand',
      component: function component() {
        return import('pages/search/models.vue');
      }
    }, {
      name: 'years',
      path: 'brand/models/:brand/years/:model',
      component: function component() {
        return import('pages/search/years.vue');
      }
    }, {
      name: 'version',
      path: 'brand/models/:brand/years/:model/version/:year',
      component: function component() {
        return import('pages/search/version.vue');
      }
    }]
  }, {
    path: 'tyres',
    name: 'tyres',
    component: function component() {
      return import('pages/tyres/index.vue');
    }
  }, {
    path: '/tyres/:id',
    name: 'tyre-detail',
    component: function component() {
      return import('pages/tyres/detail');
    }
  }, {
    path: 'tyre-family',
    name: 'tyre-family',
    component: function component() {
      return import('pages/tyre-family/index.vue');
    }
  }, {
    path: '/tyre-family/:name',
    name: 'tyre-family-view',
    component: function component() {
      return import('pages/tyre-family/view');
    }
  }, {
    path: 'search-selector',
    name: 'search-selector',
    component: function component() {
      return import('pages/search-selector.vue');
    }
  }, {
    path: 'rims',
    name: 'rims',
    component: function component() {
      return import('pages/rims/index.vue');
    }
  }, {
    path: 'mspn',
    name: 'mspn',
    component: function component() {
      return import('pages/mspn/index.vue');
    }
  }, {
    path: 'news',
    name: 'news',
    component: function component() {
      return import('pages/news/index.vue');
    }
  }, {
    path: '/news/:id',
    name: 'news-view',
    component: function component() {
      return import('pages/news/view');
    }
  }]
}]; // Always leave this as last one

if (process.env.MODE !== 'ssr') {
  routes.push({
    path: '*',
    component: function component() {
      return import('pages/error-404.vue');
    }
  });
}

export default routes;