/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.conf.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/
import Vue from 'vue';
import Quasar from 'quasar/src/vue-plugin.js';import Cookies from 'quasar/src/plugins/Cookies.js';import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';import AddressbarColor from 'quasar/src/plugins/AddressbarColor.js';import LocalStorage from 'quasar/src/plugins/LocalStorage.js';;
Vue.use(Quasar, {
  config: {
    "loading": {
      "spinnerColor": "primary",
      "backgroundColor": "white"
    }
  },
  plugins: {
    Cookies: Cookies,
    Notify: Notify,
    Loading: Loading,
    AddressbarColor: AddressbarColor,
    LocalStorage: LocalStorage
  }
});