export function title(state) {
  return state.title;
}
export function navColor(state) {
  return state.navColor;
}
export function navTextColor(state) {
  return state.navTextColor;
}
export function showNav(state) {
  return state.showNav;
}