import Vue from 'vue';
import Vuex from 'vuex';
import app from './app';
import auth from './auth';
import rim from './rim';
import tyre from './tyre';
import search from './search';
import news from './news';
import appModules from './app-modules';
import userSessions from './user-sessions';
import tyreFamilies from './tyre-families';
Vue.use(Vuex);
/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation
 */

export default new Vuex.Store({
  modules: {
    app: app,
    auth: auth,
    rim: rim,
    tyre: tyre,
    search: search,
    news: news,
    appModules: appModules,
    'user-sessions': userSessions,
    tyreFamilies: tyreFamilies
  },
  // enable strict mode (adds overhead!)
  // for dev mode only
  strict: process.env.DEV
});